.blogs_container {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  max-height: 498px;

  .blog {
    max-width: unset;

    &:nth-child(1) {
      grid-area: 1 / 1 / 3 / 2;

      @media screen and (max-width: 767px) {
        grid-area: 2 / 1 / 2 / 3;
      }
    }

    &:nth-child(2) {
      grid-area: 2 / 2 / 1 / 2;

      @media screen and (max-width: 767px) {
        grid-area: 2 / 2 / 1 / 2;
      }
    }

    &:nth-child(3) {
      grid-area: 2 / 2 / 2 / 2;

      @media screen and (max-width: 767px) {
        grid-area: 1 / 1 / 2 / 2;
      }
    }
  }
  @media screen and (max-width: 767px) {
    gap: 14px;
  }
  @media screen and (max-width: 767px) {
    gap: 8px;
  }
}